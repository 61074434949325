import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { fetchSale } from '../../helpers/request/sales'
import useFetch from '../../hooks/useFetchParams'
import { DISTRIBUTION_STATES } from '../../constants/sales'
import {
  getPreviousDistributionRoute,
  identifierCreator,
  saleStatusColor,
  saleStatusColorText,
  saleStatusIconColor
} from '../../helpers/sales'
import HeaderTitle from '../../components/shared/HeaderTitle'
import RawLoader from '../../components/shared/Loader/RawLoader'
import LoadingError from '../../components/shared/LoadingError'
import Button from '../../components/shared/Button'
import TimeLine from '../../components/Sales/SalesDetails/TimeLine'
import { PACKAGE_STATUS_ICONS, SALES_DETAIL_BUTTONS_ICONS, linkIcon } from '../../constants/icons'
import ROUTES from '../../constants/routes'
import Modal from '../../components/shared/Modal/Modal'
import FileViewer from '../../components/shared/FileViewer'
import ProductsTable from '../../components/Distribution/Packages/ProductsTable'
import { PACKAGE_STATUS_TRANSLATE, TO_CLIENT_STATUS } from '../../constants/packageStatus'

const PackageDetail = () => {
  const { packageId } = useParams()
  const [packageDetail, setPackageDetail] = useState({})
  const [openEvidence, setOpenEvidence] = useState(false)
  const navigate = useNavigate()

  const params = useMemo(() => ({ packageId, origin: 'admin' }), [packageId])

  const { isLoading, error } = useFetch(fetchSale, setPackageDetail, params)

  const location = useLocation()

  const previousRoute = getPreviousDistributionRoute(
    location,
    TO_CLIENT_STATUS[packageDetail.packageStatus]
  )

  return (
    <div className="w-full min-h-screen bg-light-grey">
      <HeaderTitle title="Paquetes" subtitle="Detalle" goBack={() => navigate(previousRoute)} />
      {isLoading && <RawLoader />}
      {error && <LoadingError />}
      {!isLoading && !error && (
        <>
          <div className="mx-10">
            <div className="border bg-white p-6 rounded">
              <div className="flex justify-between">
                <div className="font-medium">
                  Paquete: {identifierCreator(packageDetail.companyName)}-{packageDetail.saleOrder}
                  <span
                    className={`ml-2 w-fit px-2 py-1 rounded text-s ${saleStatusColor(
                      packageDetail.packageStatus
                    )}`}
                  >
                    {PACKAGE_STATUS_TRANSLATE[packageDetail.packageStatus]}
                  </span>
                </div>
                <div className="flex gap-2">
                  <Button
                    color="bg-normal-pinflag"
                    onClick={() =>
                      navigate(`${ROUTES.NEW_TICKET.split(':')[0]}${packageDetail.pinflagId}`)
                    }
                    small
                  >
                    <div className="flex gap-2">
                      <img src={SALES_DETAIL_BUTTONS_ICONS.ticket} className="w-4 h-auto" alt="" />
                      Crear Solicitud
                    </div>
                  </Button>
                  {packageDetail.evidencePhotos.courier?.length > 0 && (
                    <Button
                      border="border"
                      textColor="text-ultra-dark-grey"
                      onClick={() => setOpenEvidence(true)}
                      small
                    >
                      <div className="flex gap-2">
                        <img
                          src={SALES_DETAIL_BUTTONS_ICONS.evidencePhoto}
                          className="w-4 h-auto"
                          alt=""
                        />
                        Evidencia de entrega
                      </div>
                    </Button>
                  )}
                </div>
              </div>
              <div className="text-xs bg-semi-light-grey w-fit py-1 px-2 rounded">
                {packageDetail.products.length} productos
              </div>
              <div className="flex justify-between mt-2 text-s">
                <div>
                  <div className="text-medium-dark-grey">{packageDetail.warehouseName}</div>
                  <div>{packageDetail.warehouseId}</div>
                </div>
                <div>
                  <div className="text-medium-dark-grey">O. Transporte</div>
                  <div>
                    {packageDetail.courier?.trackingUrl && packageDetail.trackingNumber ? (
                      <button
                        type="button"
                        onClick={() =>
                          window.open(
                            `${packageDetail.courier?.trackingUrl}${
                              ['globaltracking', 'recibelo', 'alasxpress'].includes(packageDetail.courier.name)
                                ? ''
                                : packageDetail.trackingNumber
                            }`
                          )
                        }
                        className="inline-flex text-normal-pinflag underline"
                      >
                        {packageDetail.trackingNumber}
                        <img src={linkIcon} alt="link a tracking" className="ml-1 w-3 pt-1" />
                      </button>
                    ) : (
                      packageDetail.trackingNumber || '-'
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 border bg-white p-6 rounded">
              <TimeLine
                availableStatus={DISTRIBUTION_STATES}
                history={packageDetail.packageHistory}
                currentStatus={packageDetail.packageStatus}
                currentColor={saleStatusColorText(packageDetail.packageStatus)}
                currentIcon={{
                  icon: PACKAGE_STATUS_ICONS[packageDetail.packageStatus],
                  style: saleStatusIconColor(packageDetail.packageStatus)
                }}
              />
            </div>
          </div>
          <ProductsTable products={packageDetail.products} />
          <Modal
            show={openEvidence}
            title={<div className="flex gap-4 overflow-hidden">Evidencia de entrega</div>}
            handleClose={() => setOpenEvidence(false)}
          >
            <div className="mx-4">
              <FileViewer
                filteredFiles={packageDetail.evidencePhotos.courier}
              />
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default PackageDetail
