import { ASSETS_URL } from './others'
import {
  PACKAGE_BOUGHT,
  PACKAGE_DELIVERED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_ON_RETURN,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_RETURNED,
  PACKAGE_TOTAL_INCIDENT
} from './packageStatus'

export const STATES = [
  {
    value: PACKAGE_BOUGHT,
    name: 'Comprado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-1-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-1-inactive.svg`
  },
  {
    value: PACKAGE_PROCESSED,
    name: 'Procesado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-2-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-2-inactive.svg`
  },
  {
    value: PACKAGE_IN_TRANSIT,
    name: 'Enviado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-3-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-3-inactive.svg`
  },
  {
    value: PACKAGE_ON_DELIVERY,
    name: 'En reparto',
    activeIcon: `${ASSETS_URL}/filters/sales/state-3-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-3-inactive.svg`
  }
]

export const HOME_DELIVERY_STATES = [
  ...STATES,
  {
    value: PACKAGE_DELIVERED,
    name: 'Entregado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-5-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-5-inactive.svg`
  }
]

export const CLICK_AND_COLLECT_STATES = [
  ...STATES,
  {
    value: PACKAGE_RECEIVED,
    name: 'Recepcionado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-4-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-4-inactive.svg`
  },
  {
    value: PACKAGE_DELIVERED,
    name: 'Entregado',
    activeIcon: `${ASSETS_URL}/filters/sales/state-5-active.svg`,
    inactiveIcon: `${ASSETS_URL}/filters/sales/state-5-inactive.svg`
  }
]

export const DISTRIBUTION_STATES = [
  {
    value: PACKAGE_PROCESSED,
    name: 'Procesado'
  },
  {
    value: PACKAGE_IN_TRANSIT,
    name: 'En camino'
  },
  {
    value: PACKAGE_ON_DELIVERY,
    name: 'En reparto'
  },
  {
    value: PACKAGE_DELIVERED,
    name: 'Entregado'
  }
]

export const OTHERS_STATUS = [
  {
    value: PACKAGE_ON_RETURN,
    name: 'En ruta bodega'
  },
  {
    value: PACKAGE_RETURNED,
    name: 'Devuelto bodega'
  },
  {
    value: PACKAGE_TOTAL_INCIDENT,
    name: 'Incidencia total'
  }
]

export const DELIVERY_TYPES = {
  homeDelivery: 'Domicilio',
  clickAndCollect: 'Click & Collect',
  storePickup: 'Punto Propio'
}

export const DELIVERY_TYPES_LIST = [
  { id: 'homeDelivery', name: 'Domicilio' },
  { id: 'clickAndCollect', name: 'Click & Collect' },
  { id: 'storePickup', name: 'Punto Propio' }
]

export const PACKAGE_TYPES = ['ecommerce', 'marketplace', 'distribution']

export const CHANNELS = ['dafiti', 'mercadolibre', 'paris', 'ripley', 'falabella', 'hites']

export const MASIVE_CREATION_TEMPLATE = `${ASSETS_URL}/sales/template-masive-order-creation.xlsx`

export const EXPORT_MESSAGE_ERRORS = {
  PACKAGE_NOT_FOUND:
    'No se encontraron pedidos para los filtros solicitados. Si deseas obtener información de más de 3 meses atrás puedes enviar un correo a contacto@pinflag.cl o solicitarla a tu ejecutivo comercial.'
}
