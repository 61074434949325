import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

import HeaderTitle from '../../components/shared/HeaderTitle'
import ROUTES from '../../constants/routes'
import Button from '../../components/shared/Button'
import FilePicker from '../../components/shared/FilePicker/FilePicker'
import { downloadFileWhite } from '../../constants/icons'
import { downloadFileUrl } from '../../utils/files'
import { MASIVE_CREATION_TEMPLATE } from '../../constants/sales'
import CompanySelector from '../../components/Settings/CompanySelector'
import { masiveOrderCreation } from '../../helpers/request/sales'
import RawLoader from '../../components/shared/Loader/RawLoader'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import NoAccess from '../../components/NoAccess'
import { AuthContext } from '../../contexts/Store'

const MasiveOrderCreation = () => {
  const [file, setFile] = useState(null)
  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))
  const [loading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorHasData, setErrorHasData] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [failedRowsMessages, setFailedRowsMessages] = useState('')
  const navigate = useNavigate()

  const { hasAccess, loadingResources } = useContext(AuthContext)

  useEffect(() => {
    if (file) {
      setErrorHasData(false)
      setFailedRowsMessages('')
    }
  }, [file])

  const handleFailedRowsMessage = (errorRows) => {
    if (errorRows?.errors?.failedOrders?.length > 0) {
      const messages = errorRows.errors.failedOrders.map(
        (errorRow) => `Fila ${errorRow.rowId}: ${errorRow.error}`
      )
      setFailedRowsMessages(messages)
      setErrorHasData(true)
    }
  }

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('companyId', companyId)

    setIsLoading(true)
    try {
      const response = await masiveOrderCreation(formData)

      handleFailedRowsMessage(response.invalid)

      if (response.valid.count > 0) {
        setError(false)
      } else {
        setError(true)
      }
    } catch {
      setError(true)
    }
    setOpenResponseModal(true)
    setIsLoading(false)
  }

  if (!hasAccess('sales')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (loadingResources) return <div className="mt-10"><RawLoader /></div>

  return (
    <div className="bg-light-grey h-screen">
      <HeaderTitle
        title="Ventas"
        subtitle="Crear órdenes de venta"
        goBack={() => navigate(ROUTES.SALES)}
      />
      <div className="mx-16 my-6 bg-white border p-6 rounded">
        <div>Crear órdenes de venta</div>
        <div className="text-xs text-dark-grey mt-2">
          Sube una plantilla con los campos completados con la información respectiva a las nuevas
          órdenes.
        </div>
      </div>
      <div className="flex flex-col gap-2 mx-16 my-6 bg-white border p-6 rounded">
        Empresa
        <CompanySelector companyId={companyId} setCompanyId={setCompanyId} />
      </div>
      <div className="flex flex-col gap-2 mx-16 my-6 bg-white border p-6 rounded">
        <div className="flex flex-wrap justify-between">
          <div>
            <div>Masiva</div>
            <div className="text-xs text-dark-grey mb-4">
              Para generar una orden de venta masiva, descarga la plantilla y completa la
              información correspondiente.
              <br />
              Posteriormente, carga la plantilla la sección de abajo.
            </div>
          </div>
          <div className="my-auto">
            <Button
              color="bg-normal-pinflag"
              small
              onClick={() => downloadFileUrl(MASIVE_CREATION_TEMPLATE)}
            >
              <div className="flex gap-2">
                <img src={downloadFileWhite} className="h-4 w-auto" alt="download" />
                Descargar plantilla
              </div>
            </Button>
          </div>
        </div>
        <FilePicker
          fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          files={file ? [file] : []}
          removeFile={() => setFile(null)}
          handleFile={(fileContent) => setFile(fileContent[0])}
          editing
          download
        />
      </div>
      {file && (
        <div className="mx-16 flex justify-end">
          {loading ? (
            <RawLoader />
          ) : (
            <Button color="bg-normal-pinflag" onClick={handleSubmit}>
              Guardar
            </Button>
          )}
        </div>
      )}
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={() => setOpenResponseModal(false)}
        error={error}
        successMessage={
          <>
            Ordenes creadas correctamente.{' '}
            {failedRowsMessages ? (
              <div className="text-xs mt-2">
                Ordenes fallidas:{' '}
                <ul>
                  {failedRowsMessages.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ''
            )}
          </>
        }
        errorHasData={errorHasData}
        errorMessage={
          <>
            <p>¡Lo sentimos, algo ha salido mal! Por favor, inténtalo nuevamente.</p>
            <p>Si el error persiste, comunícate con nuestro soporte.</p>
            {failedRowsMessages ? (
              <div className="text-xs mt-2">
                Ordenes fallidas:{' '}
                <ul>
                  {failedRowsMessages.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ''
            )}
          </>
        }
      />
    </div>
  )
}

export default MasiveOrderCreation
