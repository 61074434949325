export const arrayToObject = (array, key) =>
  array.reduce((object, item) => {
    const newObject = object
    newObject[item.id] = item[key]
    return newObject
  }, {})

export const copyArray = (array) => JSON.parse(JSON.stringify(array))

export const compareItemsByString = (a, b, key, order) => {
  if (order === 'asc') {
    return (a[key] || '-').localeCompare(b[key] || '-')
  }
  return (b[key] || '-').localeCompare(a[key] || '-')
}

export const compareItemsByNumber = (a, b, key, order) => {
  if (order === 'asc') {
    return a[key] - b[key]
  }
  return b[key] - a[key]
}

export const compareItemsByDate = (a, b, key, order) => {
  if (order === 'asc') {
    return new Date(a[key]) - new Date(b[key])
  }
  return new Date(b[key]) - new Date(a[key])
}

export const sortArrayByKey = (array, sortValues, order) => {
  const { key, type } = sortValues

  const copiedArray = copyArray(array)

  const sorted = copiedArray.sort((a, b) => {
    if (type === 'string') return compareItemsByString(a, b, key, order)
    if (type === 'number') return compareItemsByNumber(a, b, key, order)
    if (type === 'date') return compareItemsByDate(a, b, key, order)
    return 0
  })
  return sorted
}
