import { React, useState } from 'react'

import { removeIcon, SALES_DETAIL_BUTTONS_ICONS } from '../../../constants/icons'
import { PINFLAG } from '../../../constants/general'
import { CHILEXPRESS } from '../../../constants/couriers'
import Button from '../../shared/Button'
import useGetLabels from '../useGetLabels'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import ConfirmModal from '../../shared/ConfirmModal/ConfirmModal'
import Modal from '../../shared/Modal/Modal'
import FileViewer from '../../shared/FileViewer'
import TabBar from '../../shared/TabBar/TabBar'
import { saleStatusColor } from '../../../helpers/sales'
import {
  PACKAGE_BOUGHT,
  PACKAGE_PROCESSED,
  PACKAGE_STATUS_TRANSLATE
} from '../../../constants/packageStatus'
import { hiddenTestElementClassName } from '../../../helpers/companies'

import CancelPackage from './CancelPackage/CancelPackage'
import ModifyPackage from './ModifyPackage/ModifyPackage'
import RefundPackage from './RefundPackage/RefundPackage'

const SaleHeader = ({
  order,
  saleId,
  packageStatus,
  evidencePhotos,
  deliveryType,
  saleEditableData,
  stateId,
  city,
  warehouseName,
  shippingPaid,
  productsPrice,
  products,
  paymentManager,
  courier
}) => {
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [openModifyPackage, setOpenModifyPackage] = useState(false)
  const [openCancelPackage, setOpenCancelPackage] = useState(false)
  const [openRefundPackage, setOpenRefundPackage] = useState(false)
  const [openEvidence, setOpenEvidence] = useState(false)
  const [evidencePhotoType, setEvidencePhotoType] = useState(0)

  const evidencePhotoTypes = Object.keys(evidencePhotos)

  const { getLabels, isLoadingLabels, errorMessage, openConfirm, setOpenConfirm } = useGetLabels(
    setResponseModalOpen,
    true
  )

  return (
    <>
      <div className="border bg-white p-6 rounded flex flex-wrap justify-between">
        <div className="font-medium">
          Venta: {order}
          <span className={`ml-2 w-fit px-2 py-1 rounded text-s ${saleStatusColor(packageStatus)}`}>
            {PACKAGE_STATUS_TRANSLATE[packageStatus]}
          </span>
        </div>
        <div className="flex gap-x-2">
          {Object.keys(evidencePhotos).length > 0 && (
            <Button
              onClick={() => setOpenEvidence(true)}
              border="border border-ultra-dark-grey"
              textColor="text-ultra-dark-grey"
              small
            >
              <div className="flex gap-1">
                <img
                  src={SALES_DETAIL_BUTTONS_ICONS.evidencePhoto}
                  alt="evidencia"
                  className="w-4 h-auto"
                />
                Evidencia entrega
              </div>
            </Button>
          )}
          {[PACKAGE_BOUGHT, PACKAGE_PROCESSED].includes(packageStatus) && (
            <>
              <div className={hiddenTestElementClassName()}>
                {paymentManager === PINFLAG && (
                  <Button
                      onClick={() => setOpenRefundPackage(true)}
                      border="border border-ultra-dark-grey"
                      textColor="text-ultra-dark-grey"
                      small
                    >
                      <div className="flex gap-1">
                        <img
                          src={removeIcon}
                          alt="Reembolsar"
                          className="w-4 h-auto"
                        />
                        Reembolsar
                      </div>
                  </Button>
                )}
                <Button
                  onClick={() => setOpenCancelPackage(true)}
                  border="border border-ultra-dark-grey"
                  textColor="text-ultra-dark-grey"
                  small
                >
                  <div className="flex gap-1">
                    <img
                      src={removeIcon}
                      alt="Cancelar"
                      className="w-4 h-auto"
                    />
                    Cancelar pedido
                  </div>
                </Button>
              </div>
              <Button
                onClick={() => setOpenModifyPackage(true)}
                border="border border-ultra-dark-grey"
                textColor="text-ultra-dark-grey"
                small
              >
                <div className="flex gap-1">
                  <img
                    src={SALES_DETAIL_BUTTONS_ICONS.edit}
                    alt="Editar pedido"
                    className="w-4 h-auto"
                  />
                  Editar info de entrega
                </div>
              </Button>
            </>
          )}
          <Button
            onClick={
              deliveryType === 'storePickup' && packageStatus === PACKAGE_BOUGHT
                ? () => setOpenConfirm(true)
                : () => getLabels([saleId])
            }
            border="border border-ultra-dark-grey"
            textColor="text-ultra-dark-grey"
            small
          >
            <div className="flex gap-1">
              <img src={SALES_DETAIL_BUTTONS_ICONS.download} alt="descargar" className="w-4 h-auto" />
              {!isLoadingLabels ? (
                <>Descargar etiqueta</>
              ) : (
                <>Cargando...</>
              )}
            </div>
          </Button>
        </div>
      </div>
      <ResponseModal
        handleClose={() => setResponseModalOpen(false)}
        isModalOpen={responseModalOpen}
        errorMessage={errorMessage}
        error
      />
      <ConfirmModal
        isModalOpen={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleConfirm={() => getLabels([saleId])}
        loading={isLoadingLabels}
      >
        La venta seleccionada tiene como destino un punto de retiro propio ¿Desea continuar?
      </ConfirmModal>
      <ModifyPackage
        isOpen={openModifyPackage}
        handleClose={() => setOpenModifyPackage(false)}
        saleData={saleEditableData}
        clickAndCollect={deliveryType !== 'homeDelivery'}
        stateId={stateId}
        city={city}
        warehouseName={warehouseName}
        packageId={saleId}
        packageStatus={packageStatus}
      />
      <CancelPackage
        isOpen={openCancelPackage}
        handleClose={() => setOpenCancelPackage(false)}
        pricePaid={productsPrice + shippingPaid}
        paymentManager={paymentManager}
        packageId={saleId}
      />
      <RefundPackage
        isOpen={openRefundPackage}
        handleClose={() => setOpenRefundPackage(false)}
        shippingPaid={shippingPaid}
        pricePaid={productsPrice + shippingPaid}
        packageId={saleId}
        products={products}
        paymentManager={paymentManager}
      />
      <Modal
        show={openEvidence}
        title={
          <div className="flex gap-4 overflow-hidden">
            Evidencia de entrega
            <TabBar
              tabs={evidencePhotoTypes}
              tab={evidencePhotoType}
              setTab={setEvidencePhotoType}
            />
          </div>
        }
        handleClose={() => setOpenEvidence(false)}
      >
        <div className="mx-4">
          {courier === CHILEXPRESS && evidencePhotos.courier ? (
            <a href={evidencePhotos.courier[0]} target="_blank" rel="noopener noreferrer">
              <div className='pt-5 text-center'>
                Haz click aqui para ver detalles en Chilexpress
              </div>
            </a>
          ) : (
            <FileViewer
              filteredFiles={evidencePhotos[evidencePhotoTypes[evidencePhotoType]]}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
export default SaleHeader
