import randomApi from '../../axiosConfig/randomApi'

export const getNotifications = async () => {
  const response = await randomApi().get('/notification')

  return response.data
}

export const readNotification = async (notificationId) => {
  try {
    await randomApi().put(`/notification/${notificationId}/read`)

    return true
  } catch {
    return false
  }
}
