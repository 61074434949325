import { ASSETS_URL } from './others'
import {
  PACKAGE_BOUGHT,
  PACKAGE_DELIVERED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_STORED
} from './packageStatus'

export const DASHBOARD_ICONS = {
  cardCost: `${ASSETS_URL}/dashboard-icons/icon-card-cost.svg`,
  cardIncome: `${ASSETS_URL}/dashboard-icons/icon-card-income.svg`,
  cardTicket: `${ASSETS_URL}/dashboard-icons/icon-card-ticket.svg`,
  cardOrder: `${ASSETS_URL}/dashboard-icons/icon-card-order.svg`
}

export const BILLING_ICONS = {
  excelActive: `${ASSETS_URL}/billing-icons/icon-excel-active.svg`,
  excelInactive: `${ASSETS_URL}/billing-icons/icon-excel-inactive.svg`,
  notPaid: `${ASSETS_URL}/billing-icons/icon-notpaid.svg`,
  paid: `${ASSETS_URL}/billing-icons/icon-paid.svg`,
  pdfActive: `${ASSETS_URL}/billing-icons/icon-excel-active.svg`,
  pdfInactive: `${ASSETS_URL}/billing-icons/icon-excel-inactive.svg`
}

export const SALES_ICONS = {
  printIcon: `${ASSETS_URL}/sales/print-icon.svg`,
  pictureIcon: `${ASSETS_URL}/sales/picture-icon.svg`,
  editLocationIcon: `${ASSETS_URL}/sales/edit-location-icon.svg`,
  personalInfo: `${ASSETS_URL}/sales/personal-info.png`,
  shippingInfo: `${ASSETS_URL}/sales/shipping-info.png`,
  printWhiteIcon: `${ASSETS_URL}/sales/print-icon-white.png`,
  createOrder: `${ASSETS_URL}/sales/create-order.png`,
  simpleOrder: `${ASSETS_URL}/sales/simple-order.png`,
  masiveOrder: `${ASSETS_URL}/sales/masive-order.png`
}

export const SALES_SELECTION_ICONS = {
  print: `${ASSETS_URL}/sales/selection-buttons/print.png`,
  export: `${ASSETS_URL}/sales/selection-buttons/export.png`,
  picking: `${ASSETS_URL}/sales/selection-buttons/picking.png`,
  packing: `${ASSETS_URL}/sales/selection-buttons/packing.png`,
  readyPickup: `${ASSETS_URL}/sales/selection-buttons/ready-pickup.png`
}

export const SALES_DETAIL_ICONS = {
  package: `${ASSETS_URL}/sales/detail/package.png`,
  person: `${ASSETS_URL}/sales/detail/person.png`,
  purchase: `${ASSETS_URL}/sales/detail/purchase.png`,
  shipping: `${ASSETS_URL}/sales/detail/shipping.png`,
  timeline: `${ASSETS_URL}/sales/detail/timeline.png`,
  payment: `${ASSETS_URL}/sales/detail/payment.png`,
  detail: `${ASSETS_URL}/sales/detail/detail.png`
}

export const SALES_DETAIL_BUTTONS_ICONS = {
  ticket: `${ASSETS_URL}/sales/detail-buttons/ticket-white.png`,
  evidencePhoto: `${ASSETS_URL}/sales/detail-buttons/evidence-photo.png`,
  edit: `${ASSETS_URL}/sales/detail-buttons/edit.png`,
  download: `${ASSETS_URL}/sales/detail-buttons/download.png`
}

export const LOGOS = {
  logoLogin: `${ASSETS_URL}/logos/logo-login.png`,
  logoHeader: `${ASSETS_URL}/logos/logo-header.png`,
  flag: `${ASSETS_URL}/logos/pinflag-flag.png`
}

export const PERSONALIZATION_ICONS = {
  iconCheck: `${ASSETS_URL}/custom-mailing/icon-check.png`,
  iconView: `${ASSETS_URL}/custom-mailing/icon-view.png`,
  toolTipPinmap: `${ASSETS_URL}/personalization/tooltip-pinmap.png`,
  tooltTipPinmapDelivery: `${ASSETS_URL}/personalization/tooltip-pinmap-delivery.png`,
  addContact: `${ASSETS_URL}/personalization/add-contact.png`
}

export const TICKET_ICONS = {
  ticketBlue: `${ASSETS_URL}/tickets/ticket-blue.svg`,
  chat: `${ASSETS_URL}/tickets/chat.svg`,
  switch: `${ASSETS_URL}/tickets/switch.svg`,
  files: `${ASSETS_URL}/tickets/files.svg`,
  send: `${ASSETS_URL}/tickets/send.svg`
}

export const ACTIONS = {
  viewDetails: `${ASSETS_URL}/actions/view-details.png`,
  generateLabel: `${ASSETS_URL}/actions/generate-label.png`,
  createTicket: `${ASSETS_URL}/actions/create-ticket.svg`,
  readyToPickUp: `${ASSETS_URL}/actions/ready-pickup.svg`,
  resendMail: `${ASSETS_URL}/actions/resend-email.svg`,
  archive: `${ASSETS_URL}/actions/archive.png`,
  editRule: `${ASSETS_URL}/actions/edit-rule.svg`,
  delivered: `${ASSETS_URL}/actions/delivered.svg`,
  multiPackage: `${ASSETS_URL}/actions/multi-package.png`
}

export const SHOW_EYE = {
  show: `${ASSETS_URL}/general/show-eye.svg`,
  hide: `${ASSETS_URL}/general/hide-eye.svg`
}

export const RULES_ICONS = {
  rule: `${ASSETS_URL}/rules/rule-inactive.svg`,
  priceConditionActive: `${ASSETS_URL}/rules/price-condition-active.svg`,
  priceConditionInactive: `${ASSETS_URL}/rules/price-condition-inactive.svg`,
  weightConditionActive: `${ASSETS_URL}/rules/weight-condition-active.svg`,
  weightConditionInactive: `${ASSETS_URL}/rules/weight-condition-inactive.svg`,
  priceActionActive: `${ASSETS_URL}/rules/price-action-active.svg`,
  priceActionInactive: `${ASSETS_URL}/rules/price-action-inactive.svg`,
  courierActionActive: `${ASSETS_URL}/rules/courier-action-active.svg`,
  courierActionInactive: `${ASSETS_URL}/rules/courier-action-inactive.svg`,
  deliveryActionActive: `${ASSETS_URL}/rules/delivery-action-active.svg`,
  deliveryActionInactive: `${ASSETS_URL}/rules/delivery-action-inactive.svg`,
  serviceTypeActive: `${ASSETS_URL}/rules/service-type-active.svg`,
  serviceTypeInactive: `${ASSETS_URL}/rules/service-type-inactive.svg`
}

export const FAQS_ICONS = {
  blueIcon: `${ASSETS_URL}/FAQs/blue-icon.svg`,
  sales: `${ASSETS_URL}/FAQs/sales-icon.svg`,
  seeTicketsDetails: `${ASSETS_URL}/FAQs/see-tickets-details.png`,
  ticketsStateExplanation01: `${ASSETS_URL}/FAQs/tickets-state-explanation-01.png`,
  ticketsStateExplanation02: `${ASSETS_URL}/FAQs/tickets-state-explanation-02.png`,
  ticketsStateExplanation03: `${ASSETS_URL}/FAQs/tickets-state-explanation-03.png`,
  ticketsStateExplanation04: `${ASSETS_URL}/FAQs/tickets-state-explanation-04.png`,
  editAdressState1: `${ASSETS_URL}/FAQs/edit-adress-state-1.png`,
  seeSalesDetails: `${ASSETS_URL}/FAQs/see-sales-details.png`,
  multipleLabelsGenerator: `${ASSETS_URL}/FAQs/multiple-labels-generator.png`,
  incidentsFilter: `${ASSETS_URL}/FAQs/incidents-filter.png`,
  incidentModal: `${ASSETS_URL}/FAQs/incident-modal.png`,
  readyForCollection: `${ASSETS_URL}/FAQs/ready-for-collection.png`,
  modifyPointCapacity: `${ASSETS_URL}/FAQs/modify-point-capacity.png`,
  modifyPointSchedule: `${ASSETS_URL}/FAQs/modify-point-schedule.png`
}

export const NOTIFICATIONS_ICONS = {
  tickets: `${ASSETS_URL}/notifications/tickets.svg`,
  sales: `${ASSETS_URL}/notifications/sales.svg`,
  requests: `${ASSETS_URL}/notifications/requests.svg`,
  points: `${ASSETS_URL}/notifications/points.svg`
}

export const FAQ_ASSETS = {
  seeTicketsDetails: `${ASSETS_URL}/faq-assets/faq-see-tickets-details.svg`
}

export const COURIER_ICONS = {
  downloadTemplate: `${ASSETS_URL}/couriers/download-template.png`
}

export const INCIDENTS_ICONS = {
  shipping: `${ASSETS_URL}/incidents/shipping.png`,
  person: `${ASSETS_URL}/incidents/person.png`
}

export const FILE_EXTENSION_ICONS = {
  pdf: `${ASSETS_URL}/files/pdf.svg`,
  xlsx: `${ASSETS_URL}/files/xlsx.svg`,
  zip: `${ASSETS_URL}/files/zip.svg`,
  generic: `${ASSETS_URL}/files/generic.svg`
}

export const COURIER_SERVICES_ICONS = {
  standard: `${ASSETS_URL}/courier-services/standard.png`,
  express: `${ASSETS_URL}/courier-services/express.png`,
  'same-day': `${ASSETS_URL}/courier-services/sameday.png`,
  'ship-from-store': `${ASSETS_URL}/courier-services/ship-from-store.png`
}

export const USERS_ICONS = {
  addPerson: `${ASSETS_URL}/users/add-person.png`,
  noUserAccess: `${ASSETS_URL}/users/no-user-access.png`
}

export const PACKAGE_STATUS_ICONS = {
  [PACKAGE_BOUGHT]: `${ASSETS_URL}/sales/status/successful.png`,
  [PACKAGE_PROCESSED]: `${ASSETS_URL}/sales/status/processed.png`,
  [PACKAGE_IN_TRANSIT]: `${ASSETS_URL}/sales/status/in-transit.png`,
  [PACKAGE_ON_DELIVERY]: `${ASSETS_URL}/sales/status/on-delivery.png`,
  [PACKAGE_RECEIVED]: `${ASSETS_URL}/sales/status/received.png`,
  [PACKAGE_STORED]: `${ASSETS_URL}/sales/status/received.png`,
  [PACKAGE_PICKED_UP]: `${ASSETS_URL}/sales/status/received.png`,
  [PACKAGE_DELIVERED]: `${ASSETS_URL}/sales/status/delivered.png`
}

export const STATS_ICONS = {
  time: `${ASSETS_URL}/dashboard-icons/time.png`
}

export const backArrow = `${ASSETS_URL}/general/back-arrow.svg`

export const downArrow = `${ASSETS_URL}/general/down-arrow.svg`

export const keyIcon = `${ASSETS_URL}/general/icon-login-key.svg`

export const qrIcon = `${ASSETS_URL}/general/qr-icon.png`

export const uploadIcon = `${ASSETS_URL}/general/upload.png`

export const searchIcon = `${ASSETS_URL}/general/search-icon.svg`

export const errorIcon = `${ASSETS_URL}/general/icon-error.svg`

export const editIcon = `${ASSETS_URL}/general/edit-icon.svg`

export const editIcon2 = `${ASSETS_URL}/general/edit-icon-2.svg`

export const removeIcon = `${ASSETS_URL}/general/remove-icon.svg`

export const alertIcon = `${ASSETS_URL}/general/icon-alert-yellow.svg`

export const questionIconWhite = `${ASSETS_URL}/general/question-icon-white.png`

export const questionIconDark = `${ASSETS_URL}/general/question-icon-dark.png`

export const traceabilityExplanation = `${ASSETS_URL}/traceability/explanation-image.png`

export const blackChevron = `${ASSETS_URL}/general/black-chevron.png`

export const bellIcon = `${ASSETS_URL}/general/bell-icon.svg`

export const bellWhiteIcon = `${ASSETS_URL}/general/bell-white-icon.svg`

export const boxIcon = `${ASSETS_URL}/general/box.svg`

export const emptyNotificationsBox = `${ASSETS_URL}/general/empty-notifications-box.png`

export const closeIcon = `${ASSETS_URL}/general/close-icon.svg`

export const linkIcon = `${ASSETS_URL}/general/link-icon.svg`

export const userPermission = `${ASSETS_URL}/user-permissions.svg`

export const infoIcon = `${ASSETS_URL}/general/info-icon.png`

export const moveIcon = `${ASSETS_URL}/general/move.png`

export const deleteIcon = `${ASSETS_URL}/general/delete.png`

export const arrowSplit = `${ASSETS_URL}/general/arrow-split.png`

export const settingsIcon = `${ASSETS_URL}/general/settings.png`

export const downloadIcon = `${ASSETS_URL}/general/download.svg`

export const filterIcon = `${ASSETS_URL}/general/filter.png`

export const fileIcon = `${ASSETS_URL}/general/file.png`

export const exportIcon = `${ASSETS_URL}/general/export.png`

export const chevron = `${ASSETS_URL}/general/chevron.png`

export const downloadFileWhite = `${ASSETS_URL}/general/download-file-white.png`

export const uploadFileIcon = `${ASSETS_URL}/general/uploadFile.png`
