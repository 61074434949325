import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { CREATION_STEPS } from '../../constants/tickets'
import { TICKETS_URL } from '../../constants/urls'
import ROUTES from '../../constants/routes'
import Reasons from '../../components/Tickets/NewTicket/Reasons'
import Summary from '../../components/Tickets/NewTicket/Summary'
import randomApi from '../../axiosConfig/randomApi'
import HeaderTitle from '../../components/shared/HeaderTitle'
import Stepper from '../../components/shared/Stepper'
import CreationForm from '../../components/Tickets/NewTicket/CreationForm'
import { getSale } from '../../helpers/request/sales'
import SaleSummary from '../../components/Tickets/NewTicket/SaleSummary'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import RawLoader from '../../components/shared/Loader/RawLoader'
import { AuthContext } from '../../contexts/Store'
import NoAccess from '../../components/NoAccess'

const NewTicket = () => {
  const { pinflagId } = useParams()
  const [sale, setSale] = useState({})
  const [step, setStep] = useState(0)
  const [reason, setReason] = useState('')
  const [description, setDescription] = useState('')
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)
  const [newTicketId, setNewTicketId] = useState('')
  const [loadingSale, setLoadingSale] = useState(false)

  const navigate = useNavigate()

  const location = useLocation()
  const fromIncident = location.state?.fromIncident

  const { hasAccess, loadingResources } = useContext(AuthContext)

  useEffect(() => {
    const fetchSale = async () => {
      setLoadingSale(true)
      try {
        const fetchedSale = await getSale(pinflagId)
        setSale(fetchedSale)
      } catch {
        setSale({})
      }
      setLoadingSale(false)
    }
    if (pinflagId) fetchSale()
    return () => {
      setErrorSubmit(false)
    }
  }, [pinflagId])

  const handleStep = (stepNumber) => {
    if (stepNumber < step) {
      setStep(stepNumber)
    }
  }

  const makeFormData = () => {
    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file)
    }
    formData.append('type', reason)
    formData.append('description', description)
    if (fromIncident) formData.append('fromIncident', fromIncident)
    if (Object.keys(sale).length > 0) formData.append('packageId', sale.packageId)
    return formData
  }

  const handleSubmit = async () => {
    setLoading(true)
    const formData = makeFormData()

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      const response = await randomApi().post(TICKETS_URL, formData, config)
      setNewTicketId(response.data.ticketId)
      setErrorSubmit(false)
      setStep(2)
    } catch (error) {
      setErrorSubmit(true)
    } finally {
      setLoading(false)
    }
  }

  if (!hasAccess('tickets')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  if (loadingResources) return <div className="mt-10"><RawLoader /></div>

  return (
    <div className="bg-general-background flex flex-col h-screen">
      <HeaderTitle
        title="Solicitudes"
        subtitle="Crear solicitud"
        goBack={() => navigate(ROUTES.TICKETS)}
      />
      <div className="py-4 px-8 grow">
        {loadingSale ? (
          <RawLoader />
        ) : (
          <>
            <div className="bg-white p-3 rounded-lg mb-4 flex justify-center">
              <Stepper steps={CREATION_STEPS} step={step} setStep={handleStep} />
            </div>
            <div className="flex w-full">
              {step === 0 ? (
                <Reasons
                  reason={reason}
                  setReason={setReason}
                  nextStep={() => setStep(1)}
                  sale={sale}
                  setSale={setSale}
                />
              ) : (
                <div className="flex flex-col w-full gap-4 lg:grid lg:grid-cols-4">
                  {Object.keys(sale).length > 0 && <SaleSummary sale={sale} />}
                  {step === 1 && (
                    <CreationForm
                      withSale={Object.keys(sale).length > 0}
                      reason={reason}
                      description={description}
                      setDescription={setDescription}
                      files={files}
                      setFiles={setFiles}
                      loading={loading}
                      handleSubmit={handleSubmit}
                    />
                  )}
                  {step === 2 && (
                    <Summary
                      withSale={Object.keys(sale).length > 0}
                      reason={reason}
                      description={description}
                      files={files}
                      loading={loading}
                      ticketId={newTicketId}
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}

        <ResponseModal isModalOpen={errorSubmit} error handleClose={() => setErrorSubmit(false)} />
      </div>
    </div>
  )
}

export default NewTicket
