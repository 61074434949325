import React from 'react'

import ExtraStatusFilters from './ExtraStatusFilters'

const ActionsBar = ({
  statusFilters,
  extraFilters,
  extraFiltersColor,
  selectedFilters,
  setSelectedFilters,
  rightChildren,
  tabBar,
  classNameRightChildren
}) => {
  const handleFilter = (status) => {
    if (tabBar) {
      setSelectedFilters(status.value)
    } else if (selectedFilters.includes(status.value) && !tabBar) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== status.value))
    } else {
      setSelectedFilters([...selectedFilters, status.value])
    }
  }

  return (
    <div className="mt-4 mx-10 border-x border-t rounded-t justify-stretch flex bg-white px-3 py-2">
      {statusFilters && (
        <div className="flex overflow-hidden text-s gap-2 py-1">
          <button
            className={`p-2 rounded ${
              selectedFilters.length > 0 ? '' : 'bg-semi-light-grey'
            } hover:bg-light-grey ${
              tabBar ? 'hidden' : 'block'
            }`}
            onClick={() => setSelectedFilters([])}
            type="button"
          >
            Todos
          </button>
          {statusFilters.map((status) => (
            <button
              key={status.value}
              className={`p-2 rounded whitespace-nowrap ${
                selectedFilters.includes(status.value) ? 'bg-semi-light-grey' : ''
              } hover:bg-light-grey`}
              onClick={() => handleFilter(status)}
              type="button"
            >
              {status.name}
            </button>
          ))}
        </div>
      )}
      {extraFilters && (
        <ExtraStatusFilters
          selectedFilters={selectedFilters}
          handleFilter={handleFilter}
          filters={extraFilters}
          color={extraFiltersColor}
        />
      )}
      <div className={`my-auto ${classNameRightChildren}`}>{rightChildren}</div>
    </div>
  )
}

ActionsBar.defaultProps = {
  classNameRightChildren: 'ml-auto mr-0'
}

export default ActionsBar
