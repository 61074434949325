import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NumberParam, useQueryParam } from 'use-query-params'

import CouriersTable from '../../../components/Shippings/Couriers/CouriersTable/CouriersTable'
import useFetch from '../../../hooks/useFetch'
import { COMPANY_COURIERS_URL } from '../../../constants/urls'
import Button from '../../../components/shared/Button'
import ROUTES from '../../../constants/routes'
import { settingsIcon } from '../../../constants/icons'
import NoAccess from '../../../components/NoAccess'
import { AuthContext } from '../../../contexts/Store'
import TabBar from '../../../components/shared/TabBar/TabBar'
import { flatCouriers } from '../../../helpers/couriers'
import CouriersEdit from '../../../components/Shippings/Couriers/CouriersEdit/CouriersEdit'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'
import MoreOptions from '../../../components/Shippings/Couriers/MoreOptions/MoreOptions'

const Couriers = () => {
  const [companyCouriers, setCompanyCouriers] = useState({})
  const [tab = 0, setTab] = useQueryParam('tab', NumberParam)

  const { isLoading, error } = useFetch(COMPANY_COURIERS_URL, setCompanyCouriers)

  const navigate = useNavigate()

  const { hasAccess, loadingResources } = useContext(AuthContext)

  if (!hasAccess('couriers')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  const flattedCouriers = flatCouriers(companyCouriers)

  const loading = loadingResources || isLoading

  return (
    <div className="h-screen flex flex-col bg-light-grey">
      <div className="mx-10 mt-8 flex justify-between bg-white border rounded py-2 px-6">
        <div className="my-auto font-medium">Couriers</div>
        <TabBar tabs={['Resumen', 'Editar']} tab={tab} setTab={setTab} small />
        <div className="flex gap-2">
          <Button
            color="bg-white"
            textColor="text-ultra-dark-grey"
            border="border border-ultra-dark-grey"
            onClick={() => navigate(ROUTES.COURIER_SETTINGS)}
            small
          >
            <div className="flex">
              <img src={settingsIcon} alt="configuración" className="w-4 h-4 mr-2" />
              Configuración
            </div>
          </Button>
          <MoreOptions />
        </div>
      </div>
      {loading && (
        <div className="flex justify-center my-8">
          <RawLoader />
        </div>
      )}
      {error && (
        <div className="flex justify-center my-8">
          <LoadingError />
        </div>
      )}
      {!loading && !error && tab === 0 && <CouriersTable couriers={flattedCouriers} />}
      {!loading && !error && tab === 1 && <CouriersEdit couriers={companyCouriers} />}
    </div>
  )
}

export default Couriers
