import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Modal from '../../../shared/Modal/Modal'
import Button from '../../../shared/Button'
import { SALES_ICONS } from '../../../../constants/icons'
import useFetch from '../../../../hooks/useFetchParams'
import { getStates } from '../../../../helpers/request/states'
import { UPDATE_PACKAGE_URL } from '../../../../constants/urls'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import RawLoader from '../../../shared/Loader/RawLoader'
import {
  customerValidations,
  shippingValidations
} from '../../../../constants/formValidations/modifyPackage'
import { downloadFileUrl } from '../../../../utils/files'
import randomApi from '../../../../axiosConfig/randomApi'
import { PACKAGE_PROCESSED } from '../../../../constants/packageStatus'

import FormBox from './FormBox'
import Input from './Input'

const ModifyPackage = ({
  isOpen,
  handleClose,
  packageId,
  saleData,
  clickAndCollect,
  stateId,
  city,
  warehouseName,
  packageStatus
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: saleData, mode: 'onBlur' })
  const [editing, setEditing] = useState({ personal: false, shipping: false })
  const [states, setStates] = useState({})
  const [state, setState] = useState(stateId.toString())

  const [isLoading, setIsLoading] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)

  useFetch(getStates, setStates)

  const makeBody = (data) => {
    let body = { ...data }
    if (!editing.personal) delete body.customer
    if (!editing.shipping) body = { customer: data.customer }
    return { package: body }
  }

  const onSubmit = async (data) => {
    if (!editing.personal && !editing.shipping) return
    const body = makeBody(data)
    setIsLoading(true)
    try {
      const response = await randomApi().put(UPDATE_PACKAGE_URL(packageId), body)
      if (response.data?.all_labels_url) {
        downloadFileUrl(response.data.all_labels_url)
      }
      setSubmitError(false)
    } catch {
      setSubmitError(true)
    } finally {
      setIsLoading(false)
      setOpenResponseModal(true)
    }
  }

  return (
    <Modal show={isOpen} handleClose={handleClose}>
      <div className="m-2 text-ultra-dark-grey text-lg pb-2">Editar información de entrega</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 m-2 gap-3 mb-4 lg:grid-cols-2">
          <FormBox
            icon={SALES_ICONS.personalInfo}
            title="Información Personal"
            editing={editing.personal}
            setEditing={(value) => setEditing({ ...editing, personal: value })}
          >
            <div className="flex gap-2">
              <Input
                editing={editing.personal}
                title="Nombre"
                register={register}
                registration={['customer.name']}
                defaultValue={saleData.customer.name}
              />
              <Input
                editing={editing.personal}
                title="Apellido"
                register={register}
                registration={['customer.lastname', customerValidations.lastname]}
                defaultValue={saleData.customer.lastname}
                error={errors.customer?.lastname}
              />
            </div>
            <Input
              editing={editing.personal}
              title="Mail"
              register={register}
              registration={['customer.email', customerValidations.email]}
              defaultValue={saleData.customer.email}
              error={errors.customer?.email}
            />
            <Input
              editing={editing.personal}
              title="Teléfono"
              register={register}
              registration={['customer.phone', customerValidations.phone]}
              defaultValue={saleData.customer.phone}
              error={errors.customer?.phone}
              leftText="+56"
            />
            <Input
              editing={editing.personal}
              title="RUT"
              register={register}
              registration={['customer.ssn', customerValidations.ssn]}
              defaultValue={saleData.customer.ssn}
              error={errors.customer?.ssn}
            />
          </FormBox>
          <FormBox
            icon={SALES_ICONS.shippingInfo}
            title="Entrega"
            editing={editing.shipping}
            setEditing={(value) => setEditing({ ...editing, shipping: value })}
          >
            <div className="mt-2 text-ultra-dark-grey font-light">Modo de entrega</div>
            <div className="py-1.5">
              {editing.shipping || !clickAndCollect ? 'Retiro en domicilio' : 'Retiro en tienda'}
            </div>
            {editing.shipping ? (
              <>
                <div className="mt-2 flex gap-2">
                  <div className="w-full my-1">
                    <div className="text-ultra-dark-grey font-light">Región</div>
                    <select
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      <option hidden value="" disabled>
                        Selecciona
                      </option>
                      {Object.values(states).map((currentState) => (
                        <option key={currentState.stateId} value={currentState.stateId}>
                          {currentState.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-1">
                    <div className="text-ultra-dark-grey font-light">Comuna</div>
                    <select
                      className="w-full border border-normal-grey rounded-lg p-1.5"
                      {...register('cityId', shippingValidations.cityId)}
                    >
                      <option hidden value="" disabled>
                        Selecciona
                      </option>
                      {state &&
                        states[state].cities.map((cityItem) => (
                          <option key={cityItem.id} value={cityItem.id}>
                            {cityItem.name}
                          </option>
                        ))}
                    </select>
                    {errors.cityId && <p className="text-xs text-red">{errors.cityId.message}</p>}
                  </div>
                </div>
                <Input
                  editing
                  title="Dirección"
                  register={register}
                  registration={['address', shippingValidations.address]}
                  defaultValue={saleData.address}
                  error={errors.address}
                />
                <Input
                  editing
                  title="Instrucciones (opcional)"
                  register={register}
                  registration={['deliveryInstructions']}
                  defaultValue={saleData.deliveryInstructions}
                />
              </>
            ) : (
              <div className="mt-4">
                <div className="text-ultra-dark-grey font-light">Dirección</div>
                <div className="py-1.5">
                  {clickAndCollect && <div>{warehouseName}</div>}
                  {saleData.address} {saleData.deliveryInstructions} {city}, {states[stateId]?.name}
                </div>
              </div>
            )}
          </FormBox>
        </div>
        <div className="flex flex-row-reverse justify-between m-2">
          {isLoading ? (
            <>
              <RawLoader />
              {packageStatus === PACKAGE_PROCESSED && (
                <div className="text-xs text-dark-grey max-w-sm">
                  Esto puede demorar unos segundos debido a que se está eliminando la OT
                  correspondiente
                </div>
              )}
            </>
          ) : (
            <Button
              color="bg-normal-pinflag"
              inactive={!editing.personal && !editing.shipping}
              submit
            >
              Guardar
            </Button>
          )}
        </div>
      </form>
      <ResponseModal
        isModalOpen={openResponseModal}
        handleClose={
          submitError ? () => setOpenResponseModal(false) : () => window.location.reload()
        }
        successMessage="Pedido modificado exitosamente."
        error={submitError}
      />
    </Modal>
  )
}

export default ModifyPackage
