import { React, useEffect, useState, useCallback, useContext } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'
import secureLocalStorage from 'react-secure-storage'

import { WAITING_TIMES_URL } from '../../constants/urls'
import useFetch from '../../hooks/useFetch'
import { traceabilityExplanation, questionIconWhite } from '../../constants/icons'
import EditGroupButton from '../../components/shared/EditGroupButton/EditGroupButton'
import ResponseModal from '../../components/shared/ResponseModal/ResponseModal'
import Modal from '../../components/shared/Modal/Modal'
import Button from '../../components/shared/Button'
import ToggleBar from '../../components/shared/ToggleBar'
import TabBar from '../../components/shared/TabBar/TabBar'
import RawLoader from '../../components/shared/Loader/RawLoader'
import randomApi from '../../axiosConfig/randomApi'
import LoadingError from '../../components/shared/LoadingError'
import CompanySelector from '../../components/Settings/CompanySelector'
import TraceabilityHeader from '../../components/Shippings/Traceability/TraceabilityHeader/TraceabilityHeader'
import WaitingTimesTable from '../../components/Shippings/Traceability/WaitingTimesTable'
import Header from '../../components/shared/SectionHeader2'
import { AuthContext } from '../../contexts/Store'
import NoAccess from '../../components/NoAccess'

const Traceability = () => {
  const [waitingTimes, setWaitingTimes] = useState(null)
  const [courierServices, setCourierServices] = useState([])
  const [courierService, setCourierService] = useState('')
  const [deliveryType = 0, setDeliveryType] = useQueryParam('deliveryType', NumberParam)
  const [editing, setEditing] = useState(false)
  const [editedTimes, setEditedTimes] = useState({})
  const [editedStorePickUp, setEditedStorePickUp] = useState({})
  const [openExplainModal, setOpenExplainModal] = useState(false)
  const [openResponseModal, setOpenResponseModal] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [postError, setPostError] = useState(false)
  const [companyId, setCompanyId] = useState(secureLocalStorage.getItem('companiesIds'))

  const isEdited = Object.keys(editedTimes).length > 0

  const type = deliveryType === 1 ? 'store-pickup' : courierService

  const deliveryTypes = ['Despacho', 'Mis puntos']

  const waitingTimesSetter = useCallback((data) => {
    const types = Object.keys(data)
    const services = types.filter((service) => service !== 'store-pickup')
    setCourierService(services[0])
    setCourierServices(services)
    setWaitingTimes(data)
  }, [])

  const { isLoading, error } = useFetch(WAITING_TIMES_URL, waitingTimesSetter)

  const { hasAccess, loadingResources } = useContext(AuthContext)

  useEffect(() => {
    setEditing(false)
    setOpenResponseModal(false)
    setPostError(false)
    setEditedTimes({})
    return () => {
      setEditing(false)
      setOpenResponseModal(false)
      setPostError(false)
      setEditedTimes({})
    }
  }, [])

  const getTimeValue = (name) =>
    editedTimes[name] === undefined ? waitingTimes[type][name] : editedTimes[name]

  const onChangeTimes = (editedTime, value) => {
    const newValue = value || 0
    setEditedTimes({
      ...editedTimes,
      [editedTime]: newValue
    })
  }

  const handleSubmit = async () => {
    setLoadingSubmit(true)
    const body = { ...editedTimes, type }
    if (deliveryType === 1) {
      body.storePickupTimes = editedStorePickUp
      body.companyId = companyId
    }
    try {
      await randomApi().post(WAITING_TIMES_URL, body)
      setPostError(false)
    } catch {
      setPostError(true)
    } finally {
      setLoadingSubmit(false)
      setOpenResponseModal(true)
    }
  }

  const handleCancel = () => {
    setEditedTimes({})
    setEditedStorePickUp({})
  }

  if (!hasAccess('tracking')) {
    return (
      <div className="h-screen bg-light-grey">
        <NoAccess />
      </div>
    )
  }

  return (
    <>
      <Header title="Trazabilidad" />
      <div className="flex flex-col h-[70vh]">
        <div className="bg-general-background text-xs px-12 py-8 mb-[-1.5rem]">
          <div className="flex gap-x-4 pb-6">
            <TabBar
              tabs={deliveryTypes}
              tab={deliveryType}
              setTab={(value) => {
                setDeliveryType(value)
              }}
            />
            <Button color="bg-normal-pinflag" onClick={() => setOpenExplainModal(true)} small>
              <div className="flex">
                <img src={questionIconWhite} alt="Tramos" className="mr-1 w-4 h-4" />
                Tramos
              </div>
            </Button>
            {deliveryType === 1 && (
              <CompanySelector companyId={companyId} setCompanyId={setCompanyId} />
            )}
          </div>
          <div className="flex my-2 gap-4 flex-wrap">
            {!isLoading && !error && deliveryType === 0 && courierServices.length > 1 && (
              <ToggleBar
                tabs={courierServices}
                tab={courierService}
                setTab={(service) => {
                  setCourierService(service)
                  setEditedTimes({})
                  setEditing(false)
                }}
              />
            )}
            {error && <LoadingError />}
            {!isLoading && !error && type && (
              <>
                <TraceabilityHeader
                  getTimeValue={getTimeValue}
                  onChangeTimes={onChangeTimes}
                  editing={editing}
                  setOpenExplainModal={setOpenExplainModal}
                />
                {deliveryType === 0 && (
                  <EditGroupButton
                    editing={editing}
                    setEditing={setEditing}
                    isEdited={isEdited}
                    handleCancel={() => setEditedTimes({})}
                    handleSubmit={handleSubmit}
                    isLoading={loadingSubmit}
                    small
                  />
                )}
              </>
            )}
          </div>
        </div>
        {(isLoading || loadingResources) && (
          <div className="flex justify-center mt-4">
            <RawLoader />
          </div>
        )}
        {!isLoading && !error && type && (
          <WaitingTimesTable
            waitingTimes={
              deliveryType === 1
                ? waitingTimes[type].stores[companyId].states
                : waitingTimes[type].states
            }
            storePickUp={deliveryType === 1}
            editedStorePickUp={editedStorePickUp}
            setEditedStorePickUp={setEditedStorePickUp}
            editing={editing}
          />
        )}

        {!isLoading && deliveryType === 1 && !error && (
          <div className="mx-10">
            <EditGroupButton
              editing={editing}
              setEditing={setEditing}
              isEdited
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              isLoading={loadingSubmit}
            />
          </div>
        )}
        <ResponseModal
          handleClose={() => {
            window.location.reload()
          }}
          isModalOpen={openResponseModal}
          error={postError}
          successMessage="Tiempos de espera actualizados correctamente."
        />
        <Modal show={openExplainModal} handleClose={() => setOpenExplainModal(false)}>
          <img
            src={traceabilityExplanation}
            alt="explicación de tramos"
            className="w-screen h-auto lg:w-[50vw]"
          />
        </Modal>
      </div>
    </>
  )
}

export default Traceability
